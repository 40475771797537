@use "../utils";
@use "../theme";

@mixin core-styles {
    tip-button {
        width: 1em;
        height: 1em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include theme.property(color, primary);

        &>.material-icons {
            font-size: 1.2em;
        }

        @include utils.hover-effect;
    }
}