@use "../theme";
@use "../utils";
@use "../reset";
@use "../button";

$fab-width: 4em;

@mixin core-styles {

    .floating-action-button {
        @include reset.reset-button;
        display: inline-flex;
        align-items: center;
        justify-items: center;
        height: 3.5em;
        font-size: 0.875em;
        border-radius: 2em;
        padding: 0 1em;
        text-transform: uppercase;
        font-size: .875em;
        font-weight: 500;
        letter-spacing: .08929em;



        &>.material-icons {
            font-size: 1.8em;
            margin-right: 0.5em;
        }

        @include theme.property(background-color, primary);
        @include theme.property(color, fg-on-primary);
        @include utils.hover-effect;

        &--fixed {
            position: fixed;
            right: 2em;
            bottom: 1.2em;
            z-index: 1;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, .12);

            &:hover {
                box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, .12);
            }
        }

        &--embed {
            @include button.button-shadow;
        }
    }

}