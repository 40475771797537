@use "../theme";

@mixin core-styles {

    input[type=text],
    input[type=number],
    input[type=date] {
        outline: none;
        border: none;
        background-color: inherit;
        color: inherit;
        @include theme.property(caret-color, primary);
        font-size: 1em;
        line-height: 1.75em;
        box-sizing: border-box;
        border-radius: 0;
        border-bottom: 1px solid;
        @include theme.property(border-bottom-color, input-line);
        margin-bottom: 0.4em;

        &:hover {
            @include theme.property(border-bottom-color, input-line--hover);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}