@keyframes -surface-open {
    0% {
        opacity: 0.6;
        transform: scale(0.9)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@mixin open-animation {
    animation: -surface-open 0.08s cubic-bezier(0, 0, 0.2, 1);
    will-change: transform, opacity;
}

$border-radius : 3px;