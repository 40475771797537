@use "./components/ui-components/theme";
@use "./components/ui-components/theme/dark"as dark-theme;
@use "./components/ui-components/alert";
@use "./components/ui-components/button";
@use "./components/ui-components/floating-action-button";
@use "./components/ui-components/dialog";
@use "./components/ui-components/input";
@use "./components/ui-components/tip";
@use "./components/ui-components/tip-button";

@include theme.core-styles;
@include alert.core-styles;
@include button.core-styles;
@include dialog.core-styles;
@include floating-action-button.core-styles;
@include input.core-styles;
@include tip.core-styles;
@include tip-button.core-styles;


[data-theme="dark"] {
    @include theme.declare(dark-theme.$theme-props);
}

body,
button,
input,
ul,
ol {
    font-family: 'Roboto', sans-serif;
}

ul,
ol {
    margin: 0;
    padding: 0;
    padding-inline-start: 2em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

body {
    margin: 0;
    padding: 0;
    @include theme.property(background-color, bg);
    @include theme.property(color, fg-on-bg);
    font-size: 16px;
}

p {
    margin: 0;
    padding: 0;
}

h1 {
    font-weight: 400;
}

h2,
h3 {
    font-weight: 500;
}

:root {
    @include theme.property(--spinner-color, primary);
}